main {
  min-height: calc(70vh);
}

.goodbye h1 {
  font-family: "Montserrat", sans-serif;
  font-size: calc(1vw + 24px);
  line-height: 125%;
  color: #338fc9;
}

footer {
  min-height: calc(16vh);
  width: 100%;
  background-color: #338fc9;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
}
